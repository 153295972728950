import { stringify } from "https://deno.land/std@0.208.0/yaml/stringify.ts";
import { join } from "https://deno.land/std@0.208.0/path/mod.ts";

export interface SubmissionField {
  name: string;
  label: string;
  type: "text" | "textarea" | "select";
  required?: boolean;
  options?: { value: string; label: string; }[];
  rows?: number;
  className?: string; // Add this line
}

export interface SubmissionConfig {
  title: string;
  subtitle?: string;
  submitLabel: string;
  successMessage: string;
  storageDir: string;
  fields: SubmissionField[];
}

// Handler factory function
export function createSubmissionHandler(config: SubmissionConfig) {
  return {
    async POST(req: Request) {
      const formData = await req.formData();
      
      // Create submission object with all fields
      const submission = {
        timestamp: new Date().toISOString(),
        status: "new",
        headers: Object.fromEntries(req.headers.entries()),
        ...Object.fromEntries(
          config.fields.map(field => [
            field.name,
            formData.get(field.name)?.toString() || ""
          ])
        )
      };

      // Convert to YAML
      const yamlContent = stringify(submission);
      
      // Create filename based on timestamp and first field value
      const firstFieldValue = formData.get(config.fields[0].name)?.toString() || "unnamed";
      const sanitizedName = firstFieldValue.toLowerCase().replace(/[^a-z0-9]/g, '_');
      const filename = `${Date.now()}_${sanitizedName}.yaml`;
      
      // Ensure storage directory exists
      const dataDir = join(Deno.cwd(), "data", config.storageDir);
      try {
        await Deno.mkdir(dataDir, { recursive: true });
      } catch (error) {
        console.error("Failed to create directory:", error);
        return new Response("Server error", { status: 500 });
      }

      // Write YAML file
      try {
        await Deno.writeTextFile(join(dataDir, filename), yamlContent);
        return new Response(JSON.stringify({ 
          success: true, 
          message: config.successMessage 
        }), {
          headers: { "Content-Type": "application/json" }
        });
      } catch (error) {
        console.error("Failed to write file:", error);
        return new Response("Server error", { status: 500 });
      }
    }
  };
}

export default function Submission({ config }: { config: SubmissionConfig }) {
  return (
    <div class="max-w-7xl mx-auto">
      <h1 class="text-2xl font-bold mb-2">{config.title}</h1>
      {config.subtitle && (
        <p class="text-gray-600 mb-6">{config.subtitle}</p>
      )}
      
      <div class="bg-white p-6 rounded-lg shadow">
        <form class="space-y-6" method="POST">
          {config.fields.map(field => (
            <div key={field.name}>
              <label class="block text-sm font-medium text-content">{field.label}</label>
              {field.type === "textarea" ? (
                <textarea 
                  name={field.name}
                  required={field.required}
                  class={`mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-[var(--color-primary)] focus:ring-[var(--color-primary)] dark:bg-gray-800 text-content ${field.className || ""}`}
                  rows={field.rows || 4}
                ></textarea>
              ) : field.type === "select" ? (
                <select
                  name={field.name}
                  required={field.required}
                  class={`mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-[var(--color-primary)] focus:ring-[var(--color-primary)] dark:bg-gray-800 text-content ${field.className || ""}`}
                >
                  {field.options?.map(opt => (
                    <option key={opt.value} value={opt.value}>{opt.label}</option>
                  ))}
                </select>
              ) : (
                <input 
                  name={field.name}
                  type={field.type}
                  required={field.required}
                  class={`mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-[var(--color-primary)] focus:ring-[var(--color-primary)] dark:bg-gray-800 text-content ${field.className || ""}`}
                />
              )}
            </div>
          ))}
          
          <div id="submitMessage" class="hidden text-green-600 dark:text-green-400 font-medium"></div>
          
          <button 
            type="submit"
            class="bg-[var(--color-primary)] text-white px-4 py-2 rounded-md hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-[var(--color-primary)] focus:ring-offset-2 dark:focus:ring-offset-gray-800"
          >
            {config.submitLabel}
          </button>
        </form>
      </div>

      <script dangerouslySetInnerHTML={{
        __html: `
          document.querySelector('form').addEventListener('submit', async (e) => {
            e.preventDefault();
            const form = e.target;
            const formData = new FormData(form);
            
            try {
              const response = await fetch('/api/submit?type=contact', {
                method: 'POST',
                body: formData
              });
              
              const result = await response.json();
              if (result.success) {
                const msgDiv = document.getElementById('submitMessage');
                msgDiv.textContent = result.message;
                msgDiv.classList.remove('hidden');
                form.reset();
                
                setTimeout(() => {
                  msgDiv.classList.add('hidden');
                }, 3000);
              }
            } catch (error) {
              console.error('Submission failed:', error);
              alert('Failed to submit. Please try again.');
            }
          });
        `
      }} />
    </div>
  );
}
